import React from 'react';
import fb from "../svg/facebook.svg";
import logo from "../svg/logo-fondo.png"
import linkin from "../svg/linkedin.svg"
import row from "../svg/galon-hacia-arriba.svg";

export default function Footer(props) {
    let classFooter = 'footer'
    let classRow = 'imgRow'
    if (props.opened) {
        classFooter += ' open'
        classRow += ' reversed'
    }

    /* <div className='social'>
                    <div>
                        <span>Síguenos</span>
                        <div>
                            <a href="#">
                                <img className='imagesf' src={fb} />
                            </a>
                            <a href="#">
                                <img className='imagesl' src={linkin} />
                            </a>
                        </div>
                    </div>


                </div>*/
    return (
        <footer className={classFooter}>
            <img onClick={() => props.openFooter()} className={classRow} src={row}></img>
            <section className='content-footer'>
                <div className='information'>
                    <div>
                        <span>Teléfono</span>
                        <span>+52 811 183 0242</span>
                        <span>Correo</span>
                        <a href="mailto:ventas@equiposyfluidosquimicos.com"><span>ventas@equiposyfluidosquimicos.com</span></a>
                    </div>

                </div>
               
                <div className='EFQ'>
                    <a href="/"><img src={logo} /></a>
                    <span>Equipos y Fluidos Químicos S.A de C.V</span>
                </div>
            </section>
            <section className='footer-bottom-section'>
                <div className='footer-bottom-copyright'>
                    <i className="efq-copyright" role="copyright">&copy;</i>
                    2020 <address className="footer-address" role="company address">Monterrey, NL</address><span className="footer-bottom-rights"> - Todos los derechos reservados - </span>
                </div>
                <div className="footer-bottom-wrapper">
                    <span>Diseñado por Deya & Edson Dávila</span>
                </div>
            </section>
        </footer>
    )
}
