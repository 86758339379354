import React, { Component } from 'react';

class Nosotros extends Component {


    render() {
        return (
            <section className="nosotros">

                <div>
                    <h2 className="efq-article-title-nosotros">Nosotros</h2>
                    <article className="efq-article-nosotros">
                    Quienes somos? <br/>
                        Somos una empresa dedicada a la fabricacion y elaboracion de 
                        productos quimicos y derivados que cumplan los requerimientos 
                        de nuestros clientes y les den valor agregado a sus procesos y/o productos
                        ofreciendo soluciones para la industria de fluidos quimicos.
                    </article>
                </div>
                <div>
                    <h3 className="efq-article-title-mision">Misión</h3>
                    <article className="efq-article-mision light-text">
                        <p>
                            Entregar como siempre lo hemos buscado, los estándares máximos de calidad en nuestros productos y servicios, para demostrar a nuestros clientes, el compromiso de fungir y servir como los mejores proveedores que jamás hayan tenido.
                        </p>
                        <p>
                            Destacamos nuestros estándares de calidad, que tienen el único objetivo de no solo cumplir las expectativas de los clientes, si no que llegar a superarlas, demostrar que nuestros productos, son los mejores que encontraran en el mercado, y que, si creen lo contrario, les demostraremos quienes somos.
                        </p>
                    </article>
                </div>
                <div>
                    <h3 className="efq-article-title-vision">Visión</h3>
                    <article className="efq-article-vision light-text">
                        <p>
                            Buscamos para el futuro llegar a todo tipo de empresas a los cuales, podamos brindarles cualquier tipo de producto o servicio, que manejamos o podemos manejar, con tal de satisfacer y cumplir las expectativas de nuestros clientes, así mismo como de nuestros futuros clientes, los cuales son siempre la prioridad.
                        </p>
                        <p>
                            Los estándares de calidad, así  mismo como el compromiso con la sociedad, el medio ambiente, y nuestros clientes, son la base de nuestra empresa y que procederemos a siempre implementarla en el futuro, jamás defraudar ni fallar a ninguna condición o situación que se pueda presentar, ya que nunca tendremos un “no se puede”, si no que siempre buscaremos un , “podemos solucionarlo”.

                        </p>
                    </article>
                </div>
            </section >
        );
    }
}


export default Nosotros;
