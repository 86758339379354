import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import { Form, Nav, Navbar, Button } from 'react-bootstrap';
import imgLogo from '../svg/logo-fondo.png'

class NavBarA extends Component {
  state = {}
  /*<NavLink to='/NuestrasSoluciones'>Nuestras Soluciones</NavLink>

<NavLink to='/Clientes'> Clientes</NavLink>*/
  render() {
    return (
      <Navbar collapseOnSelect expand='lg' sticky="top" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className='mr-auto'>
            <NavLink to='/'><img src={imgLogo} alt="" /></NavLink>

            <NavLink to='/Nosotros'>Nosotros</NavLink>

            <NavLink to='/Productos'>Productos</NavLink>

            
          </Nav>


          <Form inline>
            <NavLink to='/Contactanos'>
              <Button>
                Contáctanos
              </Button>
            </NavLink>

          </Form>
        </Navbar.Collapse>
      </Navbar >


    );
  }
}

export default NavBarA;
