import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import contactos from '../data/contacto'

class ContactList extends Component {

    renderContacts = contactos.map(con => {
        return (
            <>
                <ListItem key={con.id} alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt={con.Name} src="" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={con.Name}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className='inline'
                                    color="textPrimary"
                                >
                                    {con.Encharge}
                                </Typography>
                                {" — " + con.mobile}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
            </>
        )
    })

    render() {
        return (
            <List className='list'>
                {this.renderContacts}
            </List>
        );
    }
}

export default ContactList;
