import cs from '../svg/clients/CooperStandard.jpeg'
import frisa from '../svg/clients/Frisa.jpg'
import hy from '../svg/clients/HY.png'
import ksr from '../svg/clients/KSR.png'
import nemak from '../svg/clients/Nemak.jpg'
import nexteer from '../svg/clients/nexteer.jpg'
import ternium from '../svg/clients/ternium.png'
import tiAutomotive from '../svg/clients/TIAutomotive.jpg'
import usPipe from '../svg/clients/USPipe.png'

const clients = {
    data: [
        {
            id: 1,
            title: 'Cooper Standard',
            description: '',
            image: cs
        },
        {
            id: 2,
            title: 'FRISA',
            description: '',
            image: frisa
        },
        {
            id: 3,
            title: 'HYSTER-YALE GROUP',
            description: '',
            image: hy
        },
        {
            id: 4,
            title: 'KSR INTERNATIONAL CO.',
            description: '',
            image: ksr
        },
        {
            id: 5,
            title: 'Nemak',
            description: '',
            image: nemak
        },
        {
            id: 6,
            title: 'nexteer',
            description: '',
            image: nexteer
        },
        {
            id: 7,
            title: 'Ternium',
            description: '',
            image: ternium
        },
        {
            id: 8,
            title: 'TI Automotive',
            description: '',
            image: tiAutomotive
        },
        {
            id: 9,
            title: 'U.S. PIPE',
            description: '',
            image: usPipe
        },
    ]
}

export default clients;
