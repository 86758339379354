import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker'


class GoogleMaps extends Component {

    static defaultProps = {
        center: {
            lat: 25.660217,
            lng: -100.240756
        },
        zoom: 17
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '30vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBibNaa-n4V0VX84A6q3O1Hacf8wsyiB_A' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                >
                    <Marker
                        lat={25.660217}
                        lng={-100.240756}
                        name="Garibaldi"
                        color="red"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMaps;
