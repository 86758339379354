import React, { Component } from 'react';

class NuestrasSol extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <section className="nuestras">
                <div><img src="#" alt=""></img></div>
                <div>
                    <h2>Nuestras Soluciones</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ratione nulla distinctio dolorum temporibus quasi quam, reprehenderit fugiat eaque, cupiditate eum voluptatum sapiente perferendis, dolores dolore obcaecati ea tenetur quidem.
                    </p>
                </div>
            </section>
        );
    }
}

export default NuestrasSol;