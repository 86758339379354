import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

import NavBarA from './components/NavBarA';
import Principal from './Views/Principal';
import Nosotros from './Views/Nosotros';
import Productos from './Views/Productos';
import NuestrasSol from './Views/NuestrasSol';
import Clientes from './Views/Clientes';
import Contactanos from './Views/Contactanos';

class App extends Component {

    state = {};
/*<Route path='/NuestrasSoluciones' component={NuestrasSol} />
                    <Route path='/Clientes' component={Clientes} />*/
    render() {
        return (
            <React.Fragment>
                <NavBarA />
                <Switch>
                    <Route path='/' exact component={Principal} />
                    <Route path='/Nosotros' component={Nosotros} />
                    <Route path='/Productos' component={Productos} />
                    <Route path='/Contactanos' component={Contactanos} />
                </Switch>

            </React.Fragment>
        )
    }
}

export default App;
