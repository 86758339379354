import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '@material-ui/lab/TabPanel';

class TabSelect extends Component {

    state = {
        value: "1"
    };


    handleChange = (event, newValue) => {
        console.log(newValue)
        console.log(event)
        this.setState({ value: newValue })
    };

    renderTabList = this.props.types.map(type => {
        return <Tab label={type.name} value={type.id + ""} key={type.id} wrapped />
    })

    renderTabPanelList = this.props.types.map(type => {
        console.log(type)
        return <TabPanel
            value={type.id + ""}
            key={type.id}
        >
            {type.description}
            {type.types != undefined && type.types.length > 0 && <TabSelect types={type.types} />}
        </TabPanel>
    })


    render() {
        const { value } = this.state
        return (
            <TabContext value={value}>
                <AppBar
                    color="default"
                    position="static"
                >
                    <Tabs onChange={this.handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="on"
                        value={value}
                    >
                        {this.renderTabList}
                    </Tabs>
                </AppBar>
                {this.renderTabPanelList}
            </TabContext>
        );
    }
}

export default TabSelect;