import React, { Component } from 'react';
import Footer from '../components/Footer';
import GoogleMaps from '../components/GoogleMaps';
import ContactList from '../components/ContactList'

class Contactanos extends Component {

    state = {
    }
    openFooter = () => {
        this.setState((prevState) => ({ opened: !prevState.opened }))
    }

    render() {
        return (
            <section className='contactanos'>
                <h2>Contáctanos</h2>
                <div className='infoContact'>

                    <div className='direccion'>
                        <h3>Monterrey</h3>
                        <GoogleMaps />

                        <span>Direccion: 
                            <br/> Ana Cleto Zapata #3100 
                            <br/> Col Granjitas La Silla 
                            <br/> Guadalupe Nuevo Leon CP: 67170</span>

                    </div>
                    <div className='contacto'>
                       <ContactList />
                    </div>
                </div>
                <Footer opened={this.state.opened} openFooter={this.openFooter} />
            </section>

        );
    }
}

export default Contactanos;
