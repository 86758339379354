const contactos = [
    {
        id: 1,
        Name: 'Ventas',
        Encharge: '',
        mobile: '+52 811 183 0242'
    },
    {
        id: 2,
        Name: 'Ventas',
        Encharge: '',
        mobile: '+52 811 019 2505'
    },
    {
        id: 3,
        Name: 'Logistica',
        Encharge: '',
        mobile: '+52 83 98 5836'
    },
    {
        id: 4,
        Name: 'Servicio Tecnico',
        Encharge: '',
        mobile: '+52 818 287 1162'
    }
]

export default contactos