import mont from  '../svg/el-cerro-de-la-silla.jpg'
import maquinado from  '../svg/maquinado.jpg'
import aceitesHidraulicos from  '../svg/aceites hidraulicos.jpg'
import tratamientoTer from  '../svg/tratamientoTermico.jpg'
import detergentes from  '../svg/detergente.jpg'




const products = {
    data: [
        {
            id: 1,
            title: 'Maquinado',
            description: 'Aceites Refrigerantes, Aceites de corte directo',
            types: [
                {
                    id:1,
                    name: 'Aceites Refrigerantes',
                    description: 'Esta es la descripcion del Aceite Refrigerante',
                    types: [
                        {
                            id:1,
                            name: 'Mineral',
                            description: `Esta es la descripcion del mineral`,
                        },
                        {
                            id:2,
                            name: 'Semisintetico',
                            description: `Esta es la descripcion del semisintetico`,
                        },
                        {
                            id:3,
                            name: 'Sintetico',
                            description: `Esta es la descripcion del sintetico`,
                        },

                    ]
                },
                {
                    id:2,
                    name: 'Aceites De Corte Directo',
                    description: 'Esta es la descripcion del aceite de corte directo',
                    types: []
                },
            ],
            video: '',
            image: maquinado
        },
        {
            id: 2,
            title: 'Desmoldantes',
            description: 'Rubber, Alta Temperatura, Caja fria',
            types: [
                {
                    id:1,
                    name: 'Rubber',
                    description: ``,
                    types: []
                },
                {
                    id:2,
                    name: 'Soldadura',
                    description: ``,
                    types: []
                },
            ],
            video: '',
            image: mont
        },
        {
            id: 3,
            title: 'Estampados',
            description: 'Estampado',
            types: [],
            video: '',
            image: mont
        },
        {
            id: 4,
            title: 'Aceites Hidraulicos',
            description: '',
            types: [
                {
                    id:1,
                    name: 'Mineral',
                    description: ``,
                    types: []
                },
                {
                    id:2,
                    name: 'Sintetico',
                    description: ``,
                    types: []
                },
            ],
            video: '',
            image: aceitesHidraulicos
        },
        {
            id: 5,
            title: 'Tratamiento Termico',
            description: '',
            types: [],
            video: '',
            image: tratamientoTer
        },
        {
            id: 6,
            title: 'Grasas',
            description: '',
            types: [],
            video: '',
            image: mont
        },
        {
            id: 7,
            title: 'Detergentes',
            description: '',
            types: [],
            video: '',
            image: detergentes
        },
    ]
}

export default products;
