import React, { Component } from 'react';
import img from '../svg/el-cerro-de-la-silla.jpg'
import arrow from '../svg/next.svg'
import { NavLink } from 'react-router-dom'
class Principal extends Component {
    state = {}
/*<a href="./Productos">
                            
                        </a>*/ 
    render() {
        const efqTitle = 'EQUIPOS Y FLUIDOS \n QUÍMICOS, \n S.A. de C.V.'
        return (

            <section className='principal'>
                <div>
                    <h1 className='display-linebreak'>{efqTitle}</h1>
                    <img src={img} alt="" />
                </div>
                <div className='prinProd'>
                    <div>
                        <p>
                        Quienes somos? <br/>
                        Somos una empresa dedicada a la fabricacion y elaboracion de 
                        productos quimicos y derivados que cumplan los requerimientos 
                        de nuestros clientes y les den valor agregado a sus procesos y/o productos
                        ofreciendo soluciones para la industria de fluidos quimicos.
                            </p>
                    </div>
                    <div>
                        <span className="title-padding">Nuestros Productos</span>
                        <NavLink to='/Productos'><
                            img src={arrow} alt=""/>
                        </NavLink>
                        
                    </div>
                </div>
            </section>

        );
    }
}

export default Principal;
