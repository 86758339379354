import React, { Component } from 'react';
import clients from '../data/clientes';
import CardP from '../components/Card';
import Footer from '../components/Footer';

class Clientes extends Component {
    state = {
        lgShow: false,
        opened: false
    }

    renderClientes = clients.data.map(client => {
        return <CardP key={client.id} image={client.image} title={client.title} description={client.description} />
    })

    closeModal = () => {
        this.setState({ lgShow: false })
    }

    openFooter = () => {
        this.setState((prevState) => ({ opened: !prevState.opened }))
    }

    render() {

        return (
            <section className="clientes">
                <div>
                    <h2 className="efq-article-title-nosotros">Clientes</h2>
                    <article className="efq-article-mision light-text cl-desc">
                        <p>
                            Nuestro compromiso con nuestros clientes...
                        </p>
                        <p>
                            Ramón Garca, CEO.
                        </p>
                    </article>
                </div>
                <div className='cards cl-cards'>
                    {this.renderClientes}
                </div>
                <Footer opened={this.state.opened} openFooter={this.openFooter} />
            </section>
        );
    }
}

export default Clientes;
