import React, { Component } from 'react';
import CardP from '../components/Card';
import MyModal from '../components/MyModal';
import products from '../data/products';
import Footer from '../components/Footer';

class Productos extends Component {

    state = {
        lgShow: false,
        title: 0,
        image: null,
        description: '',
        listProductTypes: [],
        opened: false
    };

    showModal(te, img, desc, productTypes) {
        console.log('click')
        this.setState((prevState) => ({
            lgShow: true,
            title: te,
            image: img,
            description: desc,
            listProductTypes: productTypes
        }));
    }

    renderProducts = products.data.map(p => {
        return <CardP key={p.id} click={() => this.showModal(p.title, p.image, p.description, p.types)} image={p.image} title={p.title} description={p.description} />
    })


    closeModal = () => {
        this.setState({ lgShow: false })
    }

    openFooter = () => {
        this.setState((prevState) => ({ opened: !prevState.opened }))
    }

    handleChange = (event) => {
        if (event.keCode === 4) {
            this.closeModal()
        }
    }


    render() {
        return (
            <section className="productos" onKeyDown={this.handleChange}>

                <h2>Productos</h2>
                <div className='cards'>
                    {this.renderProducts}
                </div>
                <Footer opened={this.state.opened} openFooter={this.openFooter} />
                <MyModal lgShow={this.state.lgShow}

                    closeModal={this.closeModal}
                    title={this.state.title}
                    image={this.state.image}
                    description={this.state.description}
                    types={this.state.listProductTypes}
                />
            </section>


        );
    }
}

export default Productos;
