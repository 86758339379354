import React from 'react';
import Modal from 'react-bootstrap/Modal'
import TabSelect from './TabSelect';

export default function MyModal(props) {

    const handleChange = (event) => {
        console.log(event.keyCode)
    }

    return (
        <Modal
            size="lg"
            show={props.lgShow}
            onHide={() => props.closeModal()}
            
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-iesc">
                    <img src={props.image} alt="" />
                    <p>{props.description}</p>
                </div>

                {props.types.length > 0 && <TabSelect types={props.types} />}
            </Modal.Body>
        </Modal>
    )
}
